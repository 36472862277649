import merge from 'lodash/merge';

import { getFetchHeaders } from "../js/getToken";
import { getReport } from "../js/getReport";
import Taxonomy from "./taxonomyClass";
// import Physician from "./physicianClass";
// import Organization from "./organizationClass";

export class Provider {
    
    entityTypeClassName = 'Provider'

    constructor(data) {
        merge(this, data);
        this.taxonomy = new Taxonomy(this.taxonomy);
    }
  
    // static create (provider) {
    //     if (provider.entitytype === '1') {
    //         return new Physician(provider);
    //     } else {
    //         return new Organization(provider);
    //     }
    // }

    toString () {
        return this.name.display;
    }

    valueOf () {
        return this.npi;
    }

    async GetServiceArea () {
        try {
            const headers = await getFetchHeaders();
            const req = await fetch(`api/npi/${this.npi}/service-area/`, headers);
            const data = await req.json();
            return data;
        } catch (err) {
            console.error('error getting service area', err);
        } 
    }

    async Report (report) {
        return await getReport(this, report);
    }
  
}